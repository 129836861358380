input[type='file'] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.Field {
	flex-grow: 1;
	margin: 0 1rem 1rem 0;
	.FieldLabel {
		font-weight: bold;
		color: $color_dark_blue;
	}

	.FieldValue {
		padding-bottom: 0.2rem;
		span {
			padding: 0.7rem 0;
			display: block;
		}

		pre {
			padding: 0.6em 0em 0.6em 0.6em;
			display: block;
			font-family: $primary_font;
			margin: 0;
			white-space: pre-wrap;
         border: 1px solid rgba(34, 36, 38, 0.15);
         border-radius: 0.28571429rem;
		}

		.FieldInput_ input {
			border: 1px solid rgba(34, 36, 38, 0.15);
			padding-left: 0.8rem;
		}

		.FieldTextArea {
			width: 100%;
			background-color: white;
			border: 1px solid rgba(34, 36, 38, 0.15);
			resize: none;

			&:focus {
				outline: none;
				border: 1px solid #5e9ed6;
			}
		}

		.FieldInputFile {
			display: flex;
			div {
				flex: 1 !important;
			}
		}
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.FieldCleave {
		width: 100%;
		margin: 0;
		padding-left: 0.8rem !important;
		max-width: 100%;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		outline: 0;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		text-align: left;
		line-height: 1.21428571em;
		padding: 0.67857143em 1em;
		background: #fff;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.87);
		border-radius: 0.28571429rem;
		-webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
		transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
		transition: box-shadow 0.1s ease, border-color 0.1s ease;
		transition: box-shadow 0.1s ease, border-color 0.1s ease,
			-webkit-box-shadow 0.1s ease;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.FieldCleave_ {
		width: 100%;
		margin: 0;
		padding-left: 0.8rem !important;
		max-width: 100%;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		outline: 0;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		text-align: left;
		line-height: 1.21428571em;
		padding: 0.67857143em 0em;
		background: white;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.87);
		border-radius: 0.28571429rem;
		-webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
		transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
		transition: box-shadow 0.1s ease, border-color 0.1s ease;
		transition: box-shadow 0.1s ease, border-color 0.1s ease,
			-webkit-box-shadow 0.1s ease;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.FieldSelect_ > div {
		background-color: white !important;
	}

	.FieldDate input {
		background-color: white !important;
	}

	.FieldSearch div input {
		background-color: white !important;
	}
}

.PersonalHeader {
	margin: 0rem 1rem 1rem 1rem;
	font-size: 1.3rem;
	color: $color_blue;
	font-size: $secondary_font;
	font-weight: bold;
}
