.NavBar__Item {
	background-color: #02624f !important;
	-webkit-transition: all 0.3s ease-in-out !important;
	-moz-transition: all 0.3s ease-in-out !important;
	-o-transition: all 0.3s ease-in-out !important;
	transition: all 0.3s ease-in-out !important;
	&:hover {
		transform: scale(0.95) !important;
		-webkit-transform: scale(0.95) !important;
		-moz-transform: scale(0.95) !important;
		-o-transform: scale(0.95) !important;
	}
}

.NavBar__Outline {
	color: #f1ffff !important;
	margin-bottom: 0;
}

.NavBar__InvertedOutline {
	color: #02624f !important;
	margin-bottom: 0 !important;
   margin-top: 0 !important;
}

.NavBar__Header {
	margin: 0 !important;
	background-color: #02624f !important;
	border: 1px solid #02624f !important;

   border-radius: 0 !important;
   height: 60px;
   position: fixed;
   z-index: 1;
}

.Logout__Item {
   margin: 0 0 0.75em 0.75em !important;
}
